<template>
  <div>
    <h3>
      Park Closure Search
      <hr />
    </h3>

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label for="selectedLocation">Park</label>
        <select
          class="form-control form-control-lg"
          id="selectedLocation"
          v-model="selectedLocation"
          @change="locationChanged"
          v-if="locations"
        >
          <option value="">Select Park...</option>
          <option
            v-for="location in locations"
            :key="location.id"
            :value="location.id"
          >
            {{ location.longName }}
          </option>
        </select>
        <span
          v-else
          class="spinner-border spinner-border-sm mx-3"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>

    <button type="button" class="btn btn-primary mb-3 mt-4" @click="addClosure">
      ADD NEW PARK CLOSURE
      <i class="fa fa-plus-circle" />
    </button>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Park Closures</div>
      </div>
      <div class="card-body" v-show="closures.length > 0 && !loading">
        <div class="d-flex mb-2">
          <div class="align-self-center">Items Per Page:</div>
          <div class="ml-2">
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              aria-label="Items Per Page"
            ></b-form-select>
          </div>
        </div>
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="closures"
          :current-page="currentPage"
          :per-page="perPage"
          stacked="md"
          small
          class="mt-4"
          bordered
          sort-icon-left
        >
          <template v-slot:cell(schedule)="data">
            Recurring: {{ data.item.isRecurring ? "Yes" : "No" }}<br />
            Includes: {{ formatScheduleDays(data.item) }}
          </template>
          <template v-slot:cell(associatedSpots)="data">
            <div v-for="(spot, index) in data.item.closureSpots" :key="spot.id">
              <div v-if="index < 5">
                {{ spot.locationName }} - {{ spot.spotLoopName }} -
                {{ spot.spotName }}
              </div>

              <b-collapse :id="'spots' + data.item.id" v-if="index >= 5">
                {{ spot.locationName }} - {{ spot.spotLoopName }} -
                {{ spot.spotName }}
                <br />
              </b-collapse>
              <b-button
                v-if="
                  data.item.closureSpots.length > 5 &&
                    data.item.closureSpots.length - 1 == index
                "
                v-b-toggle="'spots' + data.item.id"
                variant="link"
                ><span class="when-open text-primary">Show less</span
                ><span class="when-closed text-primary"
                  >Show more...</span
                ></b-button
              >
            </div>
          </template>
          <template v-slot:cell(id)="data">
            <div v-if="!data.item.isDeleted">
              <button
                class="btn btn-primary btn-sm mr-2 my-1"
                @click="modify(data.item.id)"
                :disabled="areActionButtonsDisabled(data.item)"
              >
                Modify
              </button>
              <button
                class="btn btn-primary btn-sm mr-2 my-1"
                @click="deleteClosure(data.item)"
                :disabled="areActionButtonsDisabled(data.item)"
              >
                Delete
              </button>
            </div>
            <div v-else>
              Deleted {{ data.item.dateDeleted | formatDateTime }}
            </div>
          </template>
        </b-table>
        <div class="d-flex">
          <div class="mr-auto">
            Showing
            {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
            {{ perPageDisplay }} of {{ totalRows }} entries
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
      <div
        class="card-body text-center"
        v-if="closures.length === 0 && !loading"
      >
        <div class="h4 my-3">Currently no closures for this park.</div>
      </div>
      <div class="card-body text-center" v-if="loading">
        <div class="my-3">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </div>
    </div>
    <b-modal
      id="deleteModal"
      title="Delete Closure"
      ok-title="Confirm Delete"
      cancel-title="Cancel"
      @ok="confirmDelete"
    >
      <div class="font-weight-bold">
        Are you sure you want to delete this closure?
      </div>

      <div>
        {{ closureToDelete.startDate | formatDateAsUTCDate }} -
        {{ closureToDelete.endDate | formatDateAsUTCDate }}
      </div>
      <br />
      <div class="font-weight-bold">Schedule:</div>
      <div>
        Recurring: {{ closureToDelete.isRecurring ? "Yes" : "No" }}<br />
        Includes: {{ formatScheduleDays(closureToDelete) }}
      </div>
      <br />
      <div class="font-weight-bold">Closure Type:</div>
      <div>{{ closureToDelete.closureTypeName }}</div>
      <br />
      <div>
        <div class="font-weight-bold">Associated Locations:</div>
        <div v-for="spot in closureToDelete.closureSpots" :key="spot.id">
          {{ spot.locationName }} - {{ spot.spotLoopName }} -
          {{ spot.spotName }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import AdminClosureService from "@/services/admin/AdminClosureService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminSpotSearch",
  title: "Admin - Park Closure Search",
  mixins: [checkPermissionMixin],
  data() {
    return {
      selectedLocation: "",
      locations: null,
      loading: false,
      closures: [],
      closureToDelete: {},
      showMoreId: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        {
          key: "referenceId",
          label: "Reference Id",
          sortable: true
        },
        {
          key: "startDate",
          label: "From Date",
          sortable: true,
          formatter: "formatDate"
        },
        {
          key: "endDate",
          label: "To Date",
          sortable: true,
          formatter: "formatDate"
        },
        { key: "closureTypeName", label: "Closure Type", sortable: true },
        {
          key: "schedule",
          sortable: false
        },
        { key: "associatedSpots", sortable: false },
        { key: "id", sortable: false, label: "Actions/Options" }
      ]
    };
  },
  computed: {
    totalRows() {
      return this.closures.length;
    },
    perPageDisplay() {
      if (this.perPage * this.currentPage > this.totalRows) {
        return this.totalRows;
      }
      if (this.totalRows > this.perPage) {
        return this.perPage;
      }
      return this.totalRows;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    areActionButtonsDisabled(closure) {
      const locationIds = closure.closureSpots.map(n => n.locationId);
      const disabled = !this.checkLocationsPermission(
        "ParkManagementParkClosure",
        locationIds
      );
      return disabled;
    },
    confirmDelete() {
      const closureService = new AdminClosureService(this.tenantId);
      closureService.deleteClosure(this.closureToDelete.id).then(response => {
        if (response?.statusCode === "Success") {
          this.getClosures();
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Closure deleted",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    addClosure() {
      this.$router.push("/admin/park-closure-add").catch(() => {});
    },
    setShowMore(id) {
      this.showMoreId = id;
    },
    formatDate(date) {
      return this.$options.filters.formatDateAsUTCDate(date);
    },
    formatScheduleDays(item) {
      let days = "";
      let count = 0;

      if (item.includeMonday) {
        days += "Mon";
        count++;
      }
      if (item.includeTuesday) {
        if (count > 0) {
          days += ", ";
        }
        days += "Tues";
        count++;
      }
      if (item.includeWednesday) {
        if (count > 0) {
          days += ", ";
        }
        days += "Wed";
        count++;
      }
      if (item.includeThursday) {
        if (count > 0) {
          days += ", ";
        }
        days += "Thurs";
        count++;
      }
      if (item.includeFriday) {
        if (count > 0) {
          days += ", ";
        }
        days += "Fri";
        count++;
      }
      if (item.includeSaturday) {
        if (count > 0) {
          days += ", ";
        }
        days += "Sat";
        count++;
      }
      if (item.includeSunday) {
        if (count > 0) {
          days += ", ";
        }
        days += "Sun";
        count++;
      }
      return days;
    },
    associatedSpots(data) {
      data.item;
    },
    locationChanged() {
      this.$store.commit(
        "admin/setClosureSearchLocation",
        this.selectedLocation
      );
      this.getClosures();
      this.currentPage = 1;
    },
    deleteClosure(item) {
      this.closureToDelete = item;
      this.$bvModal.show("deleteModal");
    },
    modify(id) {
      this.$router.push(`/admin/park-closure-modify/${id}`).catch(() => {});
    },
    async getLocations() {
      const locationService = new AdminLocationService(this.tenantId, null);
      const response = await locationService.getLocations(this.tenantId);
      this.locations = response.data.filter(loc =>
        this.checkLocationPermission("ParkManagementParkClosure", loc.id)
      );
      this.loadingParks = false;
      const selectedLocation = this.$store.getters[
        "admin/closureSearchLocation"
      ];
      if (selectedLocation) {
        this.selectedLocation = selectedLocation;
        this.getClosures();
      }
    },
    async getClosures() {
      if (!this.selectedLocation) {
        return;
      }
      this.loading = true;
      const closureService = new AdminClosureService(this.tenantId);
      const response = await closureService.getClosures(this.selectedLocation);
      this.closures = response.data;
      this.closures = response.data.map(x => {
        return { ...x, referenceId: `C${(x.id + "").padStart(4, "0")}` };
      });
      this.loading = false;
    }
  },
  created() {
    this.getLocations();
  }
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.modal-title {
  font-weight: bold;
}
</style>
